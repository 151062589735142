var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "editor" },
    [
      !_vm.complexEditor
        ? _c("editor-content", {
            staticClass: "editor__content",
            attrs: { editor: _vm.editor },
          })
        : _vm._e(),
      _vm.complexEditor
        ? _c("ComplexEditor", {
            attrs: {
              editor_data: _vm.textData,
              placeholder: _vm.placeholder,
              "editor-type": "balloon",
            },
            on: {
              "update:editor_data": function ($event) {
                _vm.textData = $event
              },
            },
          })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }